import React from 'react'
import { Link } from 'gatsby'
import './Hero.scss'

const Hero = () => {
  return (
    <div className="hero">
      <div className="overlay" />
      <div className="hero-text-wrap">
        <h1>DevSecOps and AI From Cloud to Mission Edge</h1>
        <h2>
          Applying emerging technologies to mission-critical problems in the public & private sectors
        </h2>
        <Link
          className="btn-raised-light-blue-inverse uppercase big-shadow"
          to="/services"
        >
          Learn More
        </Link>
      </div>
      <video autoPlay loop muted src="./bg-vid2.mp4" />
      <div className="mobile-bg" />
    </div>
  )
}

export default Hero
