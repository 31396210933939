import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import Helmet from 'react-helmet'
import Container from '../components/Container'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'
import Hero from '../components/Hero/Hero'
import './base.scss'
import '../components/ServicesContent/ServicesContent'
import ServicesCard from '../components/ServicesCard/ServicesCard'
import AllFeed from '../components/AllFeed/AllFeed'
// import ClientMatrix from '../components/ClientMatrix/ClientMatrix'
import CallToActionBanner from '../components/CallToActionBanner/CallToActionBanner'
import MainSubHero from '../components/MainSubHero/MainSubHero'
import ClientMatrix from '../components/ClientMatrix/ClientMatrix'

const Index = ({ data, pageContext }) => {
  const posts = data ? data.allContentfulPost.edges : []

  return (
    <Layout headerType={'clear'}>
      <SEO />
      <Helmet>
        <title>{`${config.siteTitle}`}</title>
      </Helmet>
      <Hero />
      <MainSubHero />
      <Container type={'big-padding home'}>
        <ClientMatrix />
      </Container>
      <Container
        id={'services'}
        color={'light-grey'}
        type={'home-services home big-padding'}
      >
        <h1>
          Move your mission <span className="italic">forward</span> with cloud
          native.
        </h1>
        <div className="services-content">
          <div className="row">
            <div className="col-sm-4">
              <ServicesCard
                name={'DevSecOps'}
                id={'cloudsecops'}
                slug={'cloudsecops'}
                icon={'/cloudsecops_icon.svg'}
                description={`Accelerate application delivery, scalability, and security.`}
              />
            </div>
            <div className="col-sm-4">
              <ServicesCard
                name={'AI & Platform Development'}
                id={'digital-agility'}
                slug={'digitalagility'}
                icon={'/digital_icon.svg'}
                description={`Develop or modernize business-critical 
                applications with AI Capabilities`}
              />
            </div>
            <div className="col-sm-4">
              <ServicesCard
                id={'mobile-iot-vr'}
                description={`Build the next generation of innovative
                 systems with emerging technologies`}
                slug={'mobile'}
                name={'Edge, IoT/Mobile, and VR'}
                icon={'/mobile_icon.svg'}
              />
            </div>
          </div>
        </div>
      </Container>
      <Container type={'big-padding padding-top-none home'}>
        <AllFeed posts={posts} title limit={3} />
        {/* <ClientMatrix /> */}
      </Container>
      <CallToActionBanner type={'boost'} />
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          id
          type
          slug
          shortDescription
          publishDate(formatString: "MMMM DD, YYYY")
          heroImage {
            title
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

export default Index
