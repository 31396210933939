import React from 'react'
import { Link } from 'gatsby'
import './ContentFeedCard.scss'

class ContentFeedCard extends React.Component {
  renderIcon(type) {
    if (type === 'Podcast') {
      return <img src={'/mic-icon2.svg'} />
    } else if (type === 'Case Study') {
      return <img src={'/search-icon.svg'} />
    } else if (type === 'From the CEO') {
      return <img src={'/clip-icon.svg'} />
    } else if (type === 'Whitepaper') {
      return <img src={'/book-icon.svg'} />
    } else if (type === 'Blog Post') {
      return <img src={'/book-icon.svg'} />
    }
  }

  render() {
    const { post } = this.props
    const { slug, title } = post
    return (
      <Link
        style={{ textDecoration: 'none' }}
        key={title}
        to={'/insights/' + slug + '/'}
      >
        <div className="content-feed-card">
          <div className="image-wrap">
            <img className="main-image" src={post.heroImage.fluid.src} />
            <div className="tag-row">
              {this.renderIcon(post.type)}
              <div className="tag">{post.type}</div>
            </div>
          </div>
          <div className="text">
            <h3 className="title">{post.title}</h3>
            <p className="description">{post.shortDescription}</p>
          </div>
        </div>
      </Link>
    )
  }
}

export default ContentFeedCard
