import React from 'react'
import './MainSubHero.scss'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

export class MainSubHero extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      main: 0,
    }
  }

  componentDidMount() {}

  setIt() {
    if (this.int) clearInterval(this.int)
    this.int = setInterval(() => {
      this.switch()
    }, 8000)
  }

  switch(direction) {
    let toAdd = direction === 'up' ? 1 : -1
    this.setIt()
    this.setState({
      main: this.state.main === 2 ? 0 : this.state.main + toAdd,
    })
  }

  renderFirstSlide() {
    return (
      <div className="row inner">
        <div className="col-md-5 col-sm-4">
          <div className="image-rotation-wrap">
            <img
              className={this.state.main}
              src={'/kubernetes-kcsp-color.png'}
            />
          </div>
        </div>
        <div className="col-md-7 col-sm-8">
          <div className="text">
            <h2>
              We're a Kubernetes Certified Service Provider, and our experienced
              engineers work with large clusters every day in production.
            </h2>
            <div className="row sub-items">
              <a
                href="https://rackner.com/kubernetes"
                className="anchor-scroll-btn"
              >
                See how we help clients with end-to-end Kubernetes
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderThirdSlide() {
    return (
      <div className="row inner">
        <div className="col-md-5 col-sm-4">
          <div className="image-rotation-wrap">
            <img className={this.state.main} src={'/aws-partner-logo.png'} />
          </div>
        </div>
        <div className="col-md-7 col-sm-8">
          <div className="text">
            <h2>
              Rackner is a trusted consulting partner of Amazon Web Services and
              other major public cloud providers. We bring significant cloud
              native expertise to every client we work with.
            </h2>
            <div className="row sub-items">
              <a
                href="https://rackner.com/insights/market-enabling-ecommerce-platform-aws-30-billion"
                className="anchor-scroll-btn"
              >
                Building a market-enabling platform on AWS for a $30B+ company
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderSecondSlide() {
    return (
      <div className="row inner">
        <div className="col-md-5 col-sm-4">
          <div className="image-rotation-wrap">
            <img className={this.state.main} src={'/wasm.png'} />
          </div>
        </div>
        <div className="col-md-7 col-sm-8">
          <div className="text">
            <h2>
              We're working to bring WebAssembly and related technologies to use
              cases across Web, IoT and Edge as part of the Bytecode Alliance
            </h2>
            <div className="row sub-items">
              <a
                href="https://rackner.com/covalent"
                className="anchor-scroll-btn"
              >
                Learn about our work to make IoT and AI-at-Edge Secure and
                Accessible
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="main-sub-hero">
        <div className="svg-holder">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <polygon
              className="svg--lg"
              fill="white"
              points="24 86,100 20, 100 100, 0 100, 0 30"
            />
          </svg>
        </div>
        <div className="bg" />
        <div className="left-arrow" onClick={() => this.switch('down')}>
          <FaChevronLeft />
        </div>
        <div className="right-arrow" onClick={() => this.switch('up')}>
          <FaChevronRight />
        </div>
        {this.state.main === 0
          ? this.renderFirstSlide()
          : this.state.main === 1
          ? this.renderSecondSlide()
          : this.renderThirdSlide()}
      </div>
    )
  }
}

export default MainSubHero
