import React from 'react'
import './ClientMatrix.scss'

class ClientMatrix extends React.Component {
  render() {
    return (
      <div className="client-matrix">
        <div className="title">
          Trusted by the world's largest and most complex organizations
        </div>
        <div className="row">
          <div className="col-sm-3 col-xs-6">
            <div className="image-wrap">
              <div className="overlay" />
              <img src="/fdalogo.png" />
            </div>
          </div>
          <div className="col-sm-3 col-xs-6">
            <div className="image-wrap">
              <div className="overlay" />
              <img src="/stanford.png" />
            </div>
          </div>
          <div className="col-sm-3 col-xs-6">
            <div className="image-wrap">
              <div className="overlay" />
              <img src="/Mars-Logo.png" />
            </div>
          </div>
          <div className="col-sm-3 col-xs-6">
            <div className="image-wrap">
              <div className="overlay" />
              <img src="/aflogo.png" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3 col-xs-6">
            <div className="image-wrap">
              <div className="overlay" />
              <img src="/deptdeflogo.png" />
            </div>
          </div>
          <div className="col-sm-3 col-xs-6">
            <div className="image-wrap">
              <div className="overlay" />
              <img src="/jj-logo.png" />
            </div>
          </div>
          <div className="col-sm-3 col-xs-6">
            <div className="image-wrap">
              <div className="overlay" />
              <img src="/medstar.png" />
            </div>
          </div>
          <div className="col-sm-3 col-xs-6">
            <div className="image-wrap">
              <div className="overlay" />
              <img src="/dhslogo.png" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ClientMatrix
