import React from 'react'
import { Link } from '@reach/router'
import './AllFeed.scss'
import ContentFeedCard from '../ContentFeedCard/ContentFeedCard'

const filters = [
  {
    name: 'All',
    filter: null,
  },
  {
    name: 'Case Studies',
    filter: 'Case Study',
    icon: '/search-icon.svg',
    prefillName: 'casestudies',
  },
  /* {
    name: 'Whitepapers',
    filter: 'Whitepaper',
    icon: '/book-icon.svg',
    prefillName: 'whitepapers',
  }, */
  {
    name: 'Blog Posts',
    filter: 'Blog Post',
    icon: '/book-icon.svg',
    prefillName: 'blogposts',
  },
  {
    name: 'Podcasts',
    filter: 'Podcast',
    icon: '/mic-icon2.svg',
    prefillName: 'podcasts',
  },
]

class AllFeed extends React.Component {
  constructor(props) {
    super(props)
    let toSetFilter = null
    if (props.preFilter) {
      filters.forEach(f => {
        if (f.prefillName === props.preFilter) {
          toSetFilter = f.filter
        }
      })
    }

    this.state = {
      filter: toSetFilter,
    }
    this.selectFilter = this.selectFilter.bind(this)
  }

  selectFilter(filter) {
    if (filter === this.state.filter) {
      this.setState({
        filter: null,
      })
    } else {
      this.setState({
        filter,
      })
    }
  }

  renderFilters() {
    return filters.map((f, index) => {
      return (
        <div
          key={index}
          onClick={() => this.selectFilter(f.filter)}
          className={
            'filter ' + (this.state.filter === f.filter ? 'active' : '')
          }
        >
          {f.icon ? <img src={f.icon} /> : null}
          {f.name}
        </div>
      )
    })
  }

  renderList(filter, limit) {
    let posts

    // Filter
    if (filter) {
      posts = this.props.posts.filter(post => {
        if (post.node.type === this.state.filter) {
          return true
        } else {
          return false
        }
      })
    } else {
      posts = this.props.posts
    }

    // Render
    return posts.map((p, index) => {
      if (limit) {
        if (index < limit) {
          return (
            <div className="col-sm-6 col-md-4">
              <ContentFeedCard post={p.node} />
            </div>
          )
        }
      } else {
        return (
          <div className="col-sm-6 col-md-4">
            <ContentFeedCard post={p.node} />
          </div>
        )
      }
    })
  }

  render() {
    const { limit } = this.props
    const { filter } = this.state
    return (
      <div className="all-feed">
        {this.props.title ? <h1>Spotlight</h1> : null}
        {this.props.showFilters ? (
          <div className="filters-wrap">{this.renderFilters()}</div>
        ) : null}
        <div className="all-feed-row row">{this.renderList(filter, limit)}</div>
        {this.props.title ? (
          <div className="more-button-wrap">
            <Link to="/insights">
              <button className="btn-raised-light-blue-inverse shadow">
                More Insights
              </button>
            </Link>
          </div>
        ) : null}
      </div>
    )
  }
}

export default AllFeed
